/* Example: Style input fields */
.hs-form input[type="text"] {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
}

.hs-email {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
}
