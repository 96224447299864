

.header-text {
  color: black; /* Text color */
  font-size: 24px;
  text-align: center;
  /* You can add additional styles as needed */
}

.factorsContainer {
  display: flex; /* Use flexbox to create a flexible layout */
}

.factorsContainer {
  display: flex; /* Use flexbox to create a flexible layout */
}

.factors {
  flex: 1; /* Allow both divs to expand equally to fill the container */
  border: 1px solid #000; /* Add borders for visibility */
  padding: 10px; /* Add some padding for spacing */
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&display=swap");